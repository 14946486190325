import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';

export const StyledHamburger = styled('div')`
  display: none;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
`;

export const StyledBackground = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const StyledMenu = styled('div')`
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const StyledMenuWrapper = styled('div')`
  position: relative;
  padding: 2.2rem 2rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledMenuHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseButton = styled('button')`
  color: ${({ theme }) => theme.colors.black};
  padding: 0 0.5rem;
`;

export const StyledMenuLinks = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const StyledMenuNav = styled('nav')`
  display: block;
  width: 100%;
`;

export const StyledMenuList = styled('ul')`
  margin: 0;
  padding: 0 1rem;
`;
export const StyledMenuItem = styled('li')`
  list-style: none;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
  height: 70px;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${({ theme }) => theme.mq.sm} {
    font-size: 1.6rem;
    height: 60px;
  }
  ${({ theme }) => theme.mq.smm} {
    font-size: 1.8rem;
    height: 50px;
  }
  ${({ theme }) => theme.mq.md} {
    height: 45px;
  }
`;

export const StyledMenuLink = styled(Link)`
  cursor: pointer;
  display: block;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  text-decoration: none;
  font-weight: 500;
`;

export const StyledMenuExternalLink = styled('a')`
  cursor: pointer;
  display: block;
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
  text-decoration: none;
  font-weight: 500;
`;

export const StyledContact = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledSocial = styled('div')`
  margin: 0;
`;
