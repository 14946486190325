import React, { useEffect, useRef } from 'react';
import { navigate, useIntl } from 'gatsby-plugin-intl';
import Icon from 'components/atoms/icons/icon';
import SelectLanguage from 'components/SelectLanguage/SelectLanguage';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import withLocale from 'hoc/withLocale';
import Social from 'components/Social/Social';

import {
  showMenu,
  hideMenu,
  staggerText,
  staggerReveal,
  staggerRevealClose,
  fadeInUp,
} from 'theme/Animations';

import {
  StyledHamburger,
  StyledBackground,
  StyledMenu,
  StyledMenuWrapper,
  StyledMenuHeader,
  StyledCloseButton,
  StyledMenuLinks,
  StyledMenuNav,
  StyledMenuList,
  StyledMenuItem,
  StyledMenuLink,
  StyledSocial,
  StyledMenuExternalLink,
} from './HamburgerStyles';

const uuidv4 = require('uuid/v4');

const Scroll = require('react-scroll');

const { scroller } = Scroll;

const Hamburger = ({
  state,
  handleMenu,
  disabled,
  currentLocale,
  relatedPages = false,
}) => {
  const location = useLocation();
  let hamburgerMenu = useRef(null);
  let revealMenu = useRef(null);
  let revealMenuBackground = useRef(null);
  let socialInfo = useRef(null);
  const navLinks = useRef([]);
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      dataPl: strapiFooter(locale: { eq: "pl" }) {
        email
        phone
        linkedin
        facebook
        youtube
        instagram
      }
      dataEn: strapiFooter(locale: { eq: "en" }) {
        email
        phone
        linkedin
        facebook
        youtube
        instagram
      }
      navPl: strapiNavigation(locale: { eq: "pl" }) {
        main_nav
      }
      navEn: strapiNavigation(locale: { eq: "en" }) {
        main_nav
      }
    }
  `);

  let contact;
  if (currentLocale === 'pl') contact = data.dataPl;
  else if (currentLocale === 'en') contact = data.dataEn;

  const { linkedin, facebook, youtube, instagram } = contact;
  const socialsData = [
    {
      id: 1,
      name: 'Linkedin',
      url: linkedin,
    },
    {
      id: 2,
      name: 'Facebook',
      url: facebook,
    },
    {
      id: 3,
      name: 'Youtube',
      url: youtube,
    },
    {
      id: 4,
      name: 'Instagram',
      url: instagram,
    },
    {
      id: 5,
      name: 'Euro',
      url: intl.formatMessage({ id: 'euro_url' }),
    },
  ];

  let nav;
  if (currentLocale === 'pl') nav = data.navPl;
  else if (currentLocale === 'en') nav = data.navEn;

  const { main_nav: mainNav } = nav;

  const handleLinkAndScroll = elem => {
    if (location.pathname === '/pl/' || location.pathname === '/en/') {
      scroller.scrollTo(elem, {
        duration: 1000,
        delay: 100,
        smooth: true,
      });
    } else {
      navigate('/', { state: { scrollToSection: elem } });
    }
  };

  useEffect(() => {
    if (state.clicked === false) {
      staggerRevealClose(revealMenu, revealMenuBackground);
      showMenu(hamburgerMenu);
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      hideMenu(hamburgerMenu);
      staggerReveal(revealMenuBackground, revealMenu);
      staggerText(navLinks);
      fadeInUp([socialInfo]);
    }
  }, [state]);

  return (
    <StyledHamburger
      ref={el => {
        hamburgerMenu = el;
      }}
    >
      <StyledBackground
        ref={el => {
          revealMenuBackground = el;
        }}
      />
      <StyledMenu
        ref={el => {
          revealMenu = el;
        }}
      >
        <StyledMenuWrapper>
          <StyledMenuHeader>
            <SelectLanguage relatedPages={relatedPages} />
            <StyledCloseButton disabled={disabled} onClick={() => handleMenu()}>
              <Icon name="Close" />
            </StyledCloseButton>
          </StyledMenuHeader>
          <StyledMenuLinks>
            <StyledMenuNav>
              <StyledMenuList>
                {mainNav.map((item, index) => {
                  const { strapi_component: componentName } = item;
                  switch (componentName) {
                    case 'navigation.strona-dynamiczna': {
                      const { dynamic_page: dynamicPage } = item;
                      return (
                        <StyledMenuItem key={uuidv4()}>
                          <StyledMenuLink
                            ref={el => {
                              navLinks.current[index] = el;
                            }}
                            to={`/${dynamicPage.slug}`}
                          >
                            {dynamicPage.title}
                          </StyledMenuLink>
                        </StyledMenuItem>
                      );
                    }
                    case 'navigation.strona-statyczna': {
                      const { static_page: staticPage } = item;
                      return (
                        <StyledMenuExternalLink
                          href={staticPage.url}
                          key={uuidv4()}
                          to={staticPage.url}
                          ref={el => {
                            navLinks.current[index] = el;
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {staticPage.title}
                        </StyledMenuExternalLink>
                      );
                    }
                    case 'navigation.sekcja': {
                      if (!item.all_section) {
                        return null;
                      }

                      return (
                        <StyledMenuItem key={uuidv4()}>
                          <StyledMenuLink
                            to={
                              item.all_section.hash
                                ? `/#${item.all_section.hash}`
                                : `/#${item.all_section.name}`
                            }
                            onClick={() => {
                              handleLinkAndScroll(
                                item.all_section.hash
                                  ? `#${item.all_section.hash}`
                                  : item.all_section.name
                              );
                              handleMenu();
                            }}
                            key={uuidv4()}
                            ref={el => {
                              navLinks.current[index] = el;
                            }}
                          >
                            {item.all_section.name}
                          </StyledMenuLink>
                        </StyledMenuItem>
                      );
                    }
                    default:
                      return false;
                  }
                })}
              </StyledMenuList>
            </StyledMenuNav>
          </StyledMenuLinks>
          <div>
            <StyledSocial
              ref={el => {
                socialInfo = el;
              }}
            >
              <Social socialsData={socialsData} black />
            </StyledSocial>
          </div>
        </StyledMenuWrapper>
      </StyledMenu>
    </StyledHamburger>
  );
};

export default withLocale(Hamburger);
